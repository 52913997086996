<template>
  <div
    :class="`profile-image-container shadow ease-linear transition-all duration-150 border-${
      borderColor || 'white'
    }`"
    :style="`background-image: url(${imgUrl}); width: ${size}px; height: ${size}px; border-width: ${border}px;`"
  ></div>
</template>

<script>
export default {
  props: {
    imgUrl: String,
    size: Number,
    borderColor: String,
  },
  data() {
    return {
      border: this.size > 50 ? 3 : 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-image-container {
  background-size: cover;
  border-radius: 50%;
}
</style>
